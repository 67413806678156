import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide30/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide30/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide30/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide30/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide30/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">Cómo hacer un plan comercial en 8 pasos</h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Un plan comercial es la hoja de ruta que te guiará hacia el éxito
      empresarial, por eso, en esta guía vas a repasar los fundamentos
      esenciales que debes conocer para su correcta implementación.{" "}
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Su importancia radica en su capacidad para proporcionar claridad y enfoque.
    Un plan bien elaborado define los objetivos comerciales de manera clara y
    detallada, lo que unifica a todos los miembros del equipo bajo una visión
    compartida.
    <br /> <br />
    Al recopilar y analizar datos relevantes, ayuda a la empresa a tomar
    decisiones basadas en evidencia en lugar de depender de suposiciones. Esto
    reduce el riesgo y la incertidumbre en la toma de decisiones, lo que a su
    vez mejora las perspectivas de éxito.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Pasos para crear un resumen ejecutivo.",
      },
      {
        key: 2,
        text: "Obtener resumen de tus clientes potenciales.",
      },
      {
        key: 3,
        text: "Investigación de los competidores.",
      },
      {
        key: 4,
        text: "Conocer lo que vas a ofrecer al mercado.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
